body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  height: auto;
}

.h-full {
  height: 100%;
}

.w-full,
img {
  width: 100%;
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}

.logo-wrapper {
  max-height: 200px;
  max-width: 500px;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.bg-white {
  background-color: #ffffff;
}

.bg-blue {
  background-color: #096dd9;
}
.ant-form-item {
  margin-bottom: 6px;
}

label {
  font-weight: 400;
  color: #5f6b78;
}

textarea.ant-input {
  height: 0px;
}

.ant-form-item-control {
  line-height: 0px;
}
.ant-layout-sider-children .ant-menu-root::-webkit-scrollbar {
  width:5px; height: 5px;
}

/* Track */
.ant-layout-sider-children .ant-menu-root::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.ant-layout-sider-children .ant-menu-root::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.ant-layout-sider-children .ant-menu-root::-webkit-scrollbar-thumb:hover {
  background: #555; 
}