.login-aside {
  height: 100vh;
}

.login-h2 {
  font-size: 32px;
  font-weight: 700;
  padding: 0px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.login-h6 {
  color: #50575c;
  margin-top: 6px;
  margin-bottom: 4 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
}

.mb-3 {
  margin-bottom: 40px;
}

/* .inputbox {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #898c8f;
  font-size: 14px;
  color: #898c8f;
  font-weight: 400;
} */

.underline-none {
  text-decoration: none;
  color: #096dd9;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}

/* .ant-input:focus,
.ant-input-focused {
  box-shadow: 0 0 0 0px;
  border-right-width: 0px !important;
  outline: 0;
} */

/* input.ant-input {
  border: none;
  border-bottom: 1px solid #898c8f;
  border-radius: 0px;
} */

.primary {
  width: 100%;
  font-size: 14px;
  background-color: #096dd9;
  margin-top: 32px;
  padding: 8px 16px 8px 16px;
  border: none;
  color: #ffffff;
}

.aside_image {
  height: 100vh;
}
.image-aside {
  height: 100vh;
}

@media (max-width: 767px) {
  .aside_image {
    display: none;
  }
}
