.ant-card {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    background: $white;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-card-hoverable {
    cursor: pointer;
  }
  .ant-card-hoverable:hover {
    border-color: rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  }
  .ant-card-bordered {
    border: 1px solid #e8e8e8;
  }
  .ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: $color_2;
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1;
  }
  .ant-card-head::before,
  .ant-card-head::after {
    display: table;
    content: '';
  }
  .ant-card-head::after {
    clear: both;
  }
  .ant-card-head-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .ant-card-head-title {
    display: inline-block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-card-head .ant-tabs {
    clear: both;
    margin-bottom: -17px;
    color: $color_1;
    font-weight: normal;
    font-size: 14px;
  }
  .ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-card-extra {
    float: right;
    margin-left: auto;
    padding: 16px 0;
    color: $color_1;
    font-weight: normal;
    font-size: 14px;
  }
  .ant-card-body {
    padding: 24px;
    zoom: 1;
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: table;
    content: '';
  }
  .ant-card-body::after {
    clear: both;
  }
  .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
    margin: -1px 0 0 -1px;
    padding: 0;
  }
  .ant-card-grid {
    float: left;
    width: 33.33%;
    padding: 24px;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
            box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-card-grid:hover {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: $box-shadow-base;
            box-shadow: $box-shadow-base;
  }
  .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
    min-height: 32px;
    padding-bottom: 0;
  }
  .ant-card-contain-tabs .ant-card-extra {
    padding-bottom: 0;
  }
  .ant-card-cover > * {
    display: block;
    width: 100%;
  }
  .ant-card-cover img {
    border-radius: 2px 2px 0 0;
  }
  .ant-card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fafafa;
    border-top: 1px solid #e8e8e8;
    zoom: 1;
  }
  .ant-card-actions::before,
  .ant-card-actions::after {
    display: table;
    content: '';
  }
  .ant-card-actions::after {
    clear: both;
  }
  .ant-card-actions > li {
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }
  .ant-card-actions > li > span {
    position: relative;
    display: inline-block;
    min-width: 32px;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
  }
  .ant-card-actions > li > span:hover {
    color: $primary;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-card-actions > li > span > .anticon {
    font-size: 16px;
    line-height: 22px;
  }
  .ant-card-actions > li > span a {
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
  .ant-card-actions > li > span a:hover {
    color: $primary;
  }
  .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid #e8e8e8;
  }
  .ant-card-type-inner .ant-card-head {
    padding: 0 24px;
    background: #fafafa;
  }
  .ant-card-type-inner .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px;
  }
  .ant-card-type-inner .ant-card-body {
    padding: 16px 24px;
  }
  .ant-card-type-inner .ant-card-extra {
    padding: 13.5px 0;
  }
  .ant-card-meta {
    margin: -4px 0;
    zoom: 1;
  }
  .ant-card-meta::before,
  .ant-card-meta::after {
    display: table;
    content: '';
  }
  .ant-card-meta::after {
    clear: both;
  }
  .ant-card-meta-avatar {
    float: left;
    padding-right: 16px;
  }
  .ant-card-meta-detail {
    overflow: hidden;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-card-meta-title {
    overflow: hidden;
    color: $color_2;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-card-loading {
    overflow: hidden;
  }
  .ant-card-loading .ant-card-body {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-card-loading-content p {
    margin: 0;
  }
  .ant-card-loading-block {
    height: 14px;
    margin: 4px 0;
    background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
    background: -webkit-linear-gradient(left, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    background-size: 600% 600%;
    border-radius: 2px;
    -webkit-animation: card-loading 1.4s ease infinite;
            animation: card-loading 1.4s ease infinite;
  }
  @-webkit-keyframes card-loading {
    0%,
    100% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  @keyframes card-loading {
    0%,
    100% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  .ant-card-small > .ant-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px;
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 8px 0;
  }
  .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
    padding: 8px 0;
    font-size: 14px;
  }
  .ant-card-small > .ant-card-body {
    padding: 12px;
  }
  