.form-wrapper {
  margin: 20px;
}

.logo-wrapper {
  max-height: 200px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.register-btn {
  max-width: 250px;
  margin: 0 auto;
}
