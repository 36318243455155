.ant-input {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: $color_1;
    font-size: 14px;
    line-height: 1.5;
    background-color: $white;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: $border-radius-base;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1;
  }
  .ant-input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important;
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input[disabled]:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important;
  }
  textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    vertical-align: bottom;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  }
  .ant-input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
  }
  .ant-input-sm {
    height: 24px;
    padding: 1px 7px;
  }
  .ant-input-group {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ant-input-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-input-group > [class*='col-'] {
    padding-right: 8px;
  }
  .ant-input-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap,
  .ant-input-group > .ant-input {
    display: table-cell;
  }
  .ant-input-group-addon:not(:first-child):not(:last-child),
  .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .ant-input-group-wrap > * {
    display: block !important;
  }
  .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: $color_1;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: $border-radius-base;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-group-addon .ant-select {
    margin: -5px -11px;
  }
  .ant-input-group-addon .ant-select .ant-select-selection {
    margin: -1px;
    background-color: inherit;
    border: 1px solid transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-input-group-addon .ant-select-open .ant-select-selection,
  .ant-input-group-addon .ant-select-focused .ant-select-selection {
    color: $primary;
  }
  .ant-input-group-addon > i:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
  .ant-input-group-addon:first-child .ant-select .ant-select-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group-addon:first-child {
    border-right: 0;
  }
  .ant-input-group-addon:last-child {
    border-left: 0;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
  .ant-input-group-addon:last-child .ant-select .ant-select-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-lg .ant-input,
  .ant-input-group-lg > .ant-input-group-addon {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
  }
  .ant-input-group-sm .ant-input,
  .ant-input-group-sm > .ant-input-group-addon {
    height: 24px;
    padding: 1px 7px;
  }
  .ant-input-group-lg .ant-select-selection--single {
    height: 40px;
  }
  .ant-input-group-sm .ant-select-selection--single {
    height: 24px;
  }
  .ant-input-group .ant-input-affix-wrapper {
    display: table-cell;
    float: left;
    width: 100%;
  }
  .ant-input-group.ant-input-group-compact {
    display: block;
    zoom: 1;
  }
  .ant-input-group.ant-input-group-compact::before,
  .ant-input-group.ant-input-group-compact::after {
    display: table;
    content: '';
  }
  .ant-input-group.ant-input-group-compact::after {
    clear: both;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-input-group.ant-input-group-compact > *:last-child,
  .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-right-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top;
  }
  .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
  }
  .ant-input-affix-wrapper {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color_1;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper .ant-input {
    position: relative;
    text-align: inherit;
  }
  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    color: $color_1;
    line-height: 0;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
  .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    line-height: 1.5;
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    left: 12px;
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px;
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
  }
  .ant-input-affix-wrapper .ant-input {
    min-height: 100%;
  }
  .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-password-icon:hover {
    color: #333;
  }
  .ant-input-clear-icon {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    vertical-align: top;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-clear-icon:active {
    color: $color_1;
  }
  .ant-input-clear-icon + i {
    margin-left: 6px;
  }
  .ant-input-search-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-search-icon:hover {
    color: rgba(0, 0, 0, 0.8);
  }
  .ant-input-search-enter-button input {
    border-right: 0;
  }
  .ant-input-search-enter-button + .ant-input-group-addon,
  .ant-input-search-enter-button input + .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }