// @import "~font-awesome-sass/assets/stylesheets/_font-awesome-compass.scss";
// @import "~font-awesome-sass/assets/stylesheets/_font-awesome.scss";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300');

@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import 'spacer.scss';
@import 'variables.scss';

/** Generated by FG **/

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('/fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HelveticaNeue-Medium.woff') format('woff'),
    url('/fonts/HelveticaNeue-Medium.ttf') format('truetype'),
    url('/fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HelveticaNeue.woff') format('woff'),
    url('/fonts/HelveticaNeue.ttf') format('truetype'),
    url('/fonts/HelveticaNeue.svg#HelveticaNeue-Medium') format('svg');

  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'HelveticaNeue-Medium';
}
.login {
  h1 {
    color: $primary;
  }
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}

.logo-wrapper {
  max-height: 200px;
  max-width: 500px;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.bg-white {
  background-color: #ffffff;
}

.custom-search,
.theme-list {
  background: none !important;
}
header {
  background: #fff !important;
}
.custom-navigation {
  li {
    padding: 9px 13px;
    .custom-dropdown {
      padding: 0 !important;
    }
  }
}

.brand-logo {
  font-size: 18px;
  margin-right: auto;
  img {
    width: 150px;
    height: 40px;
    padding: 2px;
  }
}

.custom-search {
  input {
    width: 40px;
    transition: 0.5s;
    height: 30px;
    background: none;
    color: $white;
    border: none;
    &:focus {
      width: 200px;
      transition: 0.5s;
      box-shadow: none;
      border-bottom: 1px solid $white;
      border-radius: 0;
    }
  }
  span {
    // right:0 !important;
    input:not(:last-child) {
      padding-right: 0 !important;
    }
    i {
      color: $white;
      margin-right: 0;
      &:hover {
        color: $white;
      }
    }
  }
}

.theme-list {
  height: 64px;
  display: flex !important;
  align-items: center;
  background: none !important;
  ul {
    width: 140px !important;
    text-align: center !important;
    margin-top: 60px !important;
    padding: 5px 0 !important;
    background: #e6f7ff !important;
    border-top: 3px solid #1890ff !important;
    border-bottom: 3px solid #1890ff !important;
    li {
      background: none !important;
      border: 1px solid #eee;
      border-right: 1px solid #eee !important;
      margin: 1px !important;
    }
  }
}
.theme-list-item {
  display: inline-flex !important;
  height: 40px;
  margin-bottom: 0 !important;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
}
.profile-view {
  padding-right: 25px !important;
}
.language-list,
.profile-view {
  ul {
    background: #e6f7ff !important;
    border-top: 3px solid #1890ff;
    border-bottom: 3px solid #1890ff;
    li {
      color: $primary !important;
    }
  }
}

.box,
.sub-box {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}
.box {
  background: $primary;
}
.sky-bg {
  background-color: $primary;
}
.green-bg {
  background-color: $success-color;
}
.red-bg {
  background-color: $error-color;
}
.purple-bg {
  background-color: $purple;
}
.dark-blue-bg {
  background-color: $dark-blue;
}
.grey-bg {
  background-color: $grey;
}
.stats {
  h2 {
    color: $white;
    font-family: 'HelveticaNeue';
    font-size: 18px;
  }
  .stats-number {
    font-family: 'HelveticaNeue-Medium';
    font-size: 32px;
  }
  img {
    height: 38px;
  }
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: auto !important;
}

h1 {
  font-family: 'HelveticaNeue-Medium';
  font-size: 32px;
}
.mr-auto {
  margin-right: auto;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.btn-level .ant-btn.Add-btn-top {
  position: relative;
  padding-left: 10px;
  text-transform: uppercase;
  font-weight: 600;
  width: auto;
  height: 37px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.btn-block {
  width: 100%;
}
.float-right {
  float: right;
}

.bg-blue {
  background-color: #2fb9ef !important;
}

.body-height {
  width: 100%;
  margin: 0 auto;
  padding: 40px 0px;
}
.wrap-img {
  text-align: center;
}
.h2-login {
  font-weight: 900;
  font-size: 32px;
  margin-top: 120px;
  margin-bottom: 30px;
  color: #000000;
}
.h6-login {
  font-weight: 500;
  font-size: 16px;
  color: #e2e2e2;
  margin: 0px;
}
.para-text {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
}
.heading-aside-h2 {
  font-weight: 600;
  font-size: 30px;
  color: #fff;
}
Button.btn-bg-primary {
  background-color: #2fb9ef;
  padding: 0px 32px;
  border-radius: 100px;
  border: 1px solid #2fb9ef;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 20px;
}
.text-center {
  text-align: center;
}
input.input-box {
  height: 35px;
  background-color: #eeeeee;
  border: 2px solid #eeeeee;
  padding: 4px 8px;
  width: 300px;
  margin-bottom: 15px;
}

.testimonials {
  small {
    color: $secondary-text;
    font-size: 12px;
  }
  i {
    color: $secondary-text;
    font-size: 18px;
  }
}

.gridView {
  div {
    text-align: center;
    padding: 16px 0;
    color: #fff;
    &:nth-child(odd) {
      background: $primary;
    }
    &:nth-child(even) {
      background: $light-blue;
    }
  }
}
.height-120 {
  height: 120px;
}
.height-100 {
  height: 100px;
}
.height-80 {
  height: 80px;
}
.height-50 {
  height: 50px;
}

.custom-col {
  padding: 16px 10px;
  background: $primary;
  color: $white;
}

.scrollable-container {
  height: 100px;
  overflow-y: scroll;
  .background {
    padding-top: 60px;
    height: 300px;
    background-image: url(https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg);
  }
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-advanced-search-form {
  padding: 24px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.profile-details {
  .user-image {
    height: auto;
    padding: 15px;
    box-shadow: 0px 0px 3px 2px #eee;

    img {
      width: 100%;
    }
  }

  .user-details {
    position: relative;
    .floating-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 25px;
    }
    .user-ratings {
      h1 {
        margin-bottom: 0;
      }
      i {
        font-size: 22px;
        color: $primary;
      }
    }
    .user-buttons {
      button {
        border: 1px solid $primary;
        border-radius: 0;
        margin-right: 10px;
        font-size: 15px;
        font-weight: 500;
        padding: 8px 20px;
        box-shadow: none;
        height: auto;
        color: $primary;
        margin-bottom: 10px;
        &:hover {
          background: $primary;
          color: #fff !important;
        }
      }
    }
    .user-tab {
      span {
        font-weight: 600;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  svg {
    animation: spin 2s linear infinite;
  }
}

.personal-info {
  margin-bottom: 20px;
  h2 {
    margin-bottom: 0;
    color: $primary;
    font-size: 25px;
  }
}
.after-underline {
  font-size: 18px;
  position: relative;
  display: inline-block;
  padding-bottom: 8px;
  &:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid $primary;
  }
}
.contact-info {
  .contact-form {
    margin-bottom: 0 !important;
    div {
      line-height: 15px !important;
    }
    input,
    textarea {
      border: none;
      padding: 1px 0 !important;
      resize: none !important;
    }
  }
}
.skill-item {
  border-bottom: none !important;
  padding: 2px 0 !important;
  font-weight: 500;
}
.work-experience {
  h3 {
    margin-bottom: 0;
  }
}
.inner-sidebar {
  display: block;
  .ant-layout-sider-trigger {
    position: absolute;
  }
  .ant-layout-sider-children {
    .ant-menu-root {
      height: auto !important;
    }
  }
}
.demo-loadmore-list {
  min-height: 350px;
}
.auto {
  margin-left: auto !important;
}
.custom-rangepicker {
  .ant-calendar-picker {
    width: auto !important;
  }
}
.custom-pagination {
  li {
    margin-bottom: 10px;
  }
}
.ant-timeline-item-last {
  .ant-timeline-item-content {
    min-height: 30px !important;
  }
}
.custom-float {
  float: right;
}
.ant-layout {
  overflow-x: scroll !important;
}
@media only screen and (max-width: 1200px) {
  .controlled-checkbox,
  .custamized-input {
    margin-top: 10px;
  }
  .custom-margincards {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 992px) {
  .custom-form-controls {
    .ant-col-lg-24.ant-form-item-label {
      padding: 8px 0 !important;
    }
  }
  .custom-margintop {
    margin-top: 10px;
  }
  .custom-statcards,
  .custom-tocard,
  .custom-timeline {
    // margin-top: 15px;
    background-color: #5984ee;
  }
}
@media only screen and (max-width: 768px) {
  p {
    white-space: normal !important;
  }
  .intro {
    font-size: 32px !important;
  }
  .sm-block {
    display: block;
  }
  .custamized-input,
  .custom-margintop {
    margin-top: 10px;
  }
  .custom-float {
    display: block !important;
    float: none;
  }
}
@media only screen and (max-width: 576px) {
  .profile-details {
    .user-image {
      width: 250px;
      max-width: 100%;
    }
  }
  .ant-advanced-search-form {
    .ant-form-item-label {
      width: auto !important;
      margin-right: 5px;
      padding: 8px 0 !important;
    }
  }
  .custom-categories {
    padding: 10px 0 !important;
  }
  .dashboard {
    .ant-table {
      th,
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
.auto {
  margin-left: auto !important;
}
.custom-rangepicker {
  .ant-calendar-picker {
    width: auto !important;
  }
}
.custom-pagination {
  li {
    margin-bottom: 10px;
  }
}
.socialicons-margin {
  margin: 7px 0;
}
@media only screen and (max-width: 1200px) {
  .controlled-checkbox,
  .custamized-input {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .custom-form-controls {
    .ant-col-lg-24.ant-form-item-label {
      padding: 8px 0 !important;
    }
  }
  .custom-margintop {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .custamized-input,
  .custom-margintop {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .ant-advanced-search-form {
    .ant-form-item-label {
      width: auto !important;
      margin-right: 5px;
      padding: 8px 0 !important;
    }
  }
  .sm-view {
    // display:flex;
    // flex-wrap: wrap;
    overflow: scroll;
    .scroll-item {
      min-width: 500px;
    }
  }
  .ant-radio-button-wrapper {
    padding: 0 6px !important;
  }
}
.anticon.header-toggle {
  position: fixed;
  top: 0px;
  z-index: 999;
  left: 0px;
  font-size: 18px;
  color: #fff;
}
.ant-fullcalendar-fullscreen {
  min-width: 480px;
  overflow: scroll;
}

.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: none;
}

.ant-steps-vertical .ant-steps-item-title::after {
  display: none;
}
// .ant-steps-vertical .ant-steps-item-tail::after{
//   display:none;

// }

.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1890ff;
}
.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical
  > .ant-steps-item:not(:last-child)
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  display: block;
}
.ant-pagination-item {
  margin-bottom: 5px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-bottom: 5px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px !important;
}
.grand-total-bx table tbody tr td {
  padding: 10px;
  // width: 12.5%;
}
.form-group {
  margin-bottom: 5px;
}
.form-group label {
  font-weight: 500;
  color: #000;
  margin: 0 5px 0 0;
  display: inline-block;
  width: 133px;
}
.form-group label.w-auto {
  width: auto;
}
.white-box-bg {
  background: #fff;
  //box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  overflow: hidden;
}
.dash-heading {
  background: #727272;
  padding: 14px;
  border-bottom: 1px solid #ccc;
}
.dash-heading h3 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}
.form-group-box {
  padding: 15px;
}
.form-group {
  margin-bottom: 16px;
}
.form-group label {
  font-weight: 500;
  color: #0c1362;
  margin: 0 5px 0 0;
  display: inline-block;
  width: 133px;
}
.container-box {
  margin: auto;
  max-width: 800px;
  width: 100%;
  position: relative;
}
img.logo-img {
  height: auto;
  width: 170px;
}
.custom-navigation li {
  padding: 20px 15px;
}
body .sidebar-left.ant-layout-sider {
  top: 80px;
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
  flex: 0 0 220px !important;
  //box-shadow: 0 10px 10px 0 rgb(86 61 124 / 15%);
}
aside.sidebar-left aside.sidebar-left.ant-layout-sider.ant-layout-sider-dark {
  width: 220px;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  background: #fff;
  border-right: 0;

  padding: 25px 15px;
}

.sidebar-left
  ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline
  li
  span.anticon.anticon-setting {
  position: absolute;
  left: 8px;
  right: 0;
  width: 25px;
  height: 25px;
  top: 8px;
  background: rgba(12, 19, 98, 0.1);
  border-radius: 4px;
  color: #0c1362;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-left ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline li a {
  color: #0c1362;
  font-size: 12px;
  font-family: 'Work Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 15px;
  border-radius: 6px;
  position: relative;
  padding-left: 40px;
  border: 1px solid #e9e9e9; text-decoration: none;;
}
.table-grid-bx table tbody tr td h3 {
  font-size: 14px;
}

.sidebar-left
  ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline
  li
  .ant-menu-submenu-title {
  color: rgb(12, 19, 98);
  font-size: 13px;
  font-family: 'Work Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 15px;
  border-radius: 6px;
  position: relative;
  padding-left: 40px !important;
  text-overflow: inherit;
}
span.txt-sidebar {
  margin-left: 15px;
}
.sidebar-left
  ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline
  li
  i.ant-menu-submenu-arrow {
  color: rgb(12, 19, 98);
  font-size: 13px;
  font-family: 'Work Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0px;
  border-radius: 6px;
  position: relative;
  padding-left: 35px;
}
.sidebar-left ul.ant-menu.ant-menu-light.ant-menu-root li a .anticon {
  position: absolute;
  left: 8px;
  right: 0;
  width: 25px;
  height: 25px;
  top: 8px;
  background: rgb(12 19 98 / 10%);
  border-radius: 4px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body li.ant-menu-item a:hover {
  background: rgb(12 19 98 / 10%);
  color: #fff;
}

// .table-grid-bx {
//   padding: 0 10px;
// }
.table-grid-bx button {
  // background: #001529;
  padding: 2px 5px;
  color: #fff !important;
  border-radius: 3px;
  border: none;
  height: auto;
  opacity: 0.6;
  margin: 0 5px 0 0;
}
.table-grid-bx table tbody tr:nth-child(2n) td {
  background: #f5f5f5;
}
.table-grid-bx button:hover {
  opacity: 1;
}
button.ant-btn.ant-btn-danger {
  background: #f5222d;
  color: #fff;
}
button.ant-btn.view_btn.ant-btn-primary {
  background: #64a366;
  border: #64a366;
  margin-right: 12px;
}
button.ant-btn.CreditNote_btn.ant-btn-primary {
  border: 1px solid #29b0e5 !important;
  color: #fff !important;
  background: #29b0e5;
}
body .ant-table-wrapper {
  border-radius: 15px;
}

button.edit_btn {
  margin-right: 11px;
  background: #1890ff;
}
button.delete_btn {
  background: #f5222d;
}
button.ant-btn.ant-input-search-button.ant-btn-primary {
  background: transparent;
  border-left: none !important;
  border: 1px solid #d9d9d9;
}
i.anticon.anticon-search {
  color: #000;
}
body .ant-layout-sider-children .ant-menu-root {
  height: calc(100vh - 100px);
  padding: 30px 0;
  overflow: inherit;
}
body .ant-layout {
  overflow-x: auto !important;
}
body .ant-table-content {
  overflow: inherit;
  border-radius: 10px;
  background: #fff;
  // border: 1px solid #ccc;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.ant-table-body {
  overflow-x: inherit !important;
}
body .mainContnet.ant-layout-content {
  margin-top: 0 !important;
  margin-left: 200px ;
  background: #ffffff;
  padding: 100px 10px 0 15px;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #f0f2f5;
}
header.headerTop.ant-layout-header {
  background: #fff !important;
}
header.headerTop.ant-layout-header ul {
  height: 80px;
  margin-left: 0;
  padding: 0 20px 0 0;
  position: relative;
  border-radius: 0 0 0 0;
  // box-shadow: 0 5px 10px 0 rgb(86 61 124 / 15%);
}
.ant-menu-submenu-title {
  color: #000;
  font-size: 17px;
  font-weight: 500;
}
.ant-layout-sider-children ul li.ant-menu-item {
  margin: 0 !important;
}
body .ant-menu-dark,
body .ant-menu-dark body .ant-menu-sub {
  background: #ffffff;
}
.sidebar-left
  .ant-layout-sider-children
  ul
  li
  i.anticon.anticon-dashboard
  span {
  float: left;
}

.dash-bg-white {
  background: #fff;
  margin: 0 0 5px;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ddd;
  overflow: hidden;
}
.item-search .ant-input-group {
  position: relative;
}
.item-search .ant-input-group input.ant-input {
  height: 32px;
  border-radius: 100px;
  padding: 0 15px;
}
.item-search .ant-input-group span.ant-input-group-addon {
  position: absolute;
  right: 15px;
  width: auto;
  top: 4px;
  height: auto;
}
.item-search .ant-input-group span.ant-input-group-addon button.ant-btn {
  background: #fff;
  box-shadow: inherit;
  border: none;
  font-size: 16px;
  padding: 0;
  color: #29afe4;
  height: auto;
}
.create-quotation a.btn,
.create-quotation .search-btn-group {
  border: 1px solid #229aca;
  padding: 0 20px;
  border-radius: 100px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 30px;
  background: rgba(34, 154, 202, 0.1);
  color: #229aca;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-top-heading h3 {
  margin: 8px 0 0;
}
.customer-list-ul ul {
  margin: 15px 0 0;
}
.customer-list-ul ul li {
  list-style: none;
  font-weight: 600;
  font-size: 15px;
  color: #000;
  margin: 0 0 10px;
}
.customer-list-ul ul li span {
  color: #0074e3;
  font-weight: 700;
}
section.ant-layout {
  background: #ffffff;
}

.ant-form-item-label > label {
  font-weight: 500;
  color: #333;
}

input.ant-input.item-search {
  height: 32px;
  border-radius: 100px;
  padding: 0 15px;
  margin-right: 5px;
}

button.ant-btn.btn-secondary {
  border: 1px dashed #fd7e14;
  display: inline-block;
  border-radius: 100px;
  padding: 0px 20px;
  margin: 20px 0px;
  width: 100%;
  font-size: 16px;
  height: 32px;
  color: #fd7e14;
  background: rgba(255, 255, 255, 0.1);
}

.btn-group-success {
  text-align: right;
}

button.ant-btn.btn-success {
  border: 1px solid rgb(92, 184, 92);
  background-color: rgb(255, 255, 255);
  padding: 0px;
  color: rgb(92, 184, 92) !important;
  font-size: 14px;
  width: 150px;
  font-weight: 600;
  margin: 20px 10px;
}
button.ant-btn.btn-success:hover {
  border: 1px solid rgb(92, 184, 92);
  color: rgb(255, 255, 255) !important;
  padding: 0px;
  background-color: rgb(92, 184, 92);
  font-size: 14px;
  width: 150px;
  font-weight: 600;
  margin: 20px 10px;
}

.dash-bg-white.no-padding {
  padding: 0;
  border-radius: 20px;
}
.basic-customer-details {
  display: inline-block;
  width: 100%;
}
body textarea.ant-input {
  resize: none;
}
body .ant-input {
  box-shadow: inherit;
  border-radius: 2px;
  font-size: 12px;
  height: 30px;
  background-color: #232a7100;
  padding: 0 7px;
  border: 1px solid #232a7136;
}
html body {
  font-family: 'Work Sans', sans-serif;
  background-color: #fff;
}
// body .ant-form-item-label {
//   line-height: normal;
// }
body .ant-form-item-label label {
  line-height: normal;
  margin-bottom: 5px;
  font-size: 14px;
  color: #7a7a7a;
  display: inline-block;
  width: 100%;
}
.table-scroll-auto table thead tr th {
  white-space: nowrap;
  width: 12.5%;
  font-size: 13px;
  padding: 10px 16px;
}
.table-scroll-auto table tbody tr td {
  font-size: 12px;
}
.ant-select-selection.ant-select-selection--multiple {
  background-color: #caf0f854;
  border: 1px solid #caf0f8;
}
.ant-select {
  width: 100%;
}
.ant-select-selection.ant-select-selection--single {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  height: 30px;
  border: 1px solid #232a7136;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
}
input.ant-input.ant-input-disabled {
  background-color: #caf0f854;
  border: 1px solid #caf0f8;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  padding-right: 20px;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  color: #7a7a7a;
  text-overflow: ellipsis;
}
ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline li.ant-menu-item {
  padding: 0 !important;
  margin: 0 0 5px !important;
  overflow: visible;
}
.dash-bg-white.no-padding .ant-table-content {
  border: none;
  overflow: inherit;
  box-shadow: inherit;
}
.table-grid-bx table thead tr th {
  white-space: nowrap;
  background: #4f548d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.table-grid-bx table tbody tr td {
  font-size: 13px;
  color: #333;
}
.section-top-heading h3 {
  // color: #f15a2b !important;
  margin: 0;
}
.right-text {
  text-align: right;
  margin: 7px 0 0;
}
.avatar-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}
.d-inline-block {
  display: inline-block;
  width: 100%;
}
.align-right {
  text-align: right;
}
.width-11 .table-scroll-auto table thead tr th,
.width-11 .table-scroll-auto table tbody tr td {
  width: 150px;
  min-width: 150px;
}
.width-11 .grand-total-bx table tbody tr td {
  width: 150px;
  min-width: 150px;
}
body .ant-card-body {
  padding: 0;
}
.dataSource-grid table tr th {
  width: 25%;
  white-space: nowrap;
  background: #727272;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.cancel-policy-button button {
  background: #fff;
  padding: 7px 25px;
  font-size: 14px;
  border-radius: 4px;
  border: #0fbcff 1px solid;
  color: #0097d1;
  font-weight: 500;
}
.cancel-policy-button {
  margin: 30px 0 0;
}
.cancel-policy-button button:hover {
  background: #0fbcff;
  color: #fff;
}
.form-group span.ant-calendar-picker {
  max-width: 200px;
  width: 100%;
}
.cancel-policy-button label.blank-space {
  display: inline-block;
  width: 140px;
}
.add-button .add-secondary {
  color: rgb(253, 126, 20);
  border-color: rgb(253, 126, 20);
  width: 100%;
}
.add-button {
  margin: 22px 0 0;
}

.container-box .white-box-bg {
  box-shadow: inherit;
}
body .dataSource-grid .ant-table-content {
  box-shadow: inherit;
  border: 1px solid #ddd;
}

.watermark-text {
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  transform: rotate(-20deg);
  right: 130px;
  margin: auto;
  white-space: nowrap;
  height: 100px;
}
.watermark-text h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 70px;
  font-weight: 900;
  opacity: 0.1;
  text-transform: uppercase;
  text-align: center;
}
.position-relative {
  position: relative;
}
.cancel-policy-button.plr-15 {
  margin: 0;
  padding: 15px;
  position: relative;
}
.position-relative .watermark-text {
  top: 50%;
}
.policy-list-details ul li {
  margin: 15px 0 0;
  color: rgba(0, 0, 0, 0.65);
}
.policy-list-details ul li span {
  display: inline-block;
  width: 170px;
  color: #000;
}
.outrisk-logo {
  margin: 0 0 25px;
}
.policy-list-details ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.customer-heading {
  background: #999;
  padding: 7px 15px;
  font-size: 18px;
  border-radius: 7px 7px 0 0;
}
.policy-list-details {
  padding: 0 15px 15px;
  border: 1px solid #999;
  border-radius: 0 0 8px 8px;
}
.customer-heading h4 {
  color: #fff;
  margin: 0;
}
.customer-main-block {
  width: 690px;
  margin: 0 auto;
}
.dash-bg-white.no-padding .table-grid-bx {
  overflow: auto;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.form-group-login .ant-row.ant-form-item {
  display: inline-block;
  width: 100%;
  margin: 15px 0 30px;
}

.form-group-login .ant-row.ant-form-item span.ant-form-item-children input {
  display: block;
  width: 100%;
  padding: 7px 2px;
  margin-bottom: 0;
  border-bottom: 1px solid gray;
  background: white !important;
  color: #969696 !important;
  border-radius: 5px;
  border: 1px solid transparent;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
  box-shadow: 0 0 10px #00000021;
  height: auto;
}
body .ant-input-affix-wrapper .ant-input-suffix {
  top: 17px;
}

.ant-spin-spinning.loader-icon-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11111;
  background: rgba(255, 255, 255, 0.8);
}

.ant-spin-lg.loader-icon-fixed .ant-spin-dot {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.dashboard-heading-top {
  margin: 0 0 15px;
}
.dashboard-heading-top h1 {
  font-family: 'Work Sans', sans-serif;
  text-transform: capitalize;
  font-weight: 900;
  font-size: 28px;
  color: #f25f30;
  margin: 0;
  text-align: left;
}

.ant-card.card-body-bx {
  background-image: linear-gradient(to right, #5b73e8, #44c4fa);
  border-radius: 6px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
}
.ant-card.card-body-bx.purple-bg {
  background-image: linear-gradient(to right, #664dc9, #9884ea);
}
.ant-card.card-body-bx.green-bg {
  background-image: linear-gradient(to right, #1d976c, #2fd38a);
}
.ant-card.card-body-bx.orange-bg {
  background-image: linear-gradient(to right, #fa5420, #f6a800);
}
.ant-card.card-body-bx.red-bg {
  background-image: linear-gradient(to right, #f5222d, #ff878d);
}
.ant-card.card-body-bx.yellow-bg {
  background-image: linear-gradient(to right, #fea22e, #ffba0c);
  margin-right: 15px;
  cursor: pointer;
  padding: 0;
  border-radius: 7px !important;
}
.ant-card-body {
  padding: 15px !important;
  text-align: center;
}
a.head_select {
  color: rgb(24 144 255) !important;
  margin-left: 40px;
  font-size: 20px;
}
.card-body-link {
  display: inline-block;
  width: 100%;
  padding: 15px;
}
.card-body-link h4 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.card-body-link h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  margin: 0;
}

.ant-card.dash-white-bg-shadow {
  border-radius: 10px;
}
.dash-white-bg-shadow {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
  overflow: hidden;
  height: 100%;
}
.graph-heading {
  padding: 15px 15px;
}
.graph-heading h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #0c1362;
  margin: 0;
}
.recent-heading {
  padding: 15px 15px;
  border-bottom: 1px solid #ddd;
}
.recent-heading h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #0c1362;
  margin: 0;
}
.recent-post-list {
  padding: 15px;
}
.recent-block-in {
  position: relative;
  padding: 0 0px 0 0px;
  margin: 0 0 0px;
}

span.recent-no {
  position: absolute;
  left: 0;
  width: 30px;
  top: 4px;
  background: #5a76e9;
  height: 30px;
  border-radius: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
}
.recent-content h5 {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.recent-content p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  color: #838383;
}
span.recent-no.bg-green {
  background: #2ecf88;
}
.dash-white-tabs .ant-tabs-bar.ant-tabs-top-bar {
  border-top: 5px solid #47b8f7;
  margin: 0;
}
.dash-white-tabs .ant-tabs-bar.ant-tabs-top-bar .ant-tabs-nav .ant-tabs-tab {
  border: none;
  color: #000;
  margin: 0;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 600;
}
.dash-white-tabs
  .ant-tabs-bar.ant-tabs-top-bar
  .ant-tabs-nav
  .ant-tabs-tab-active {
  border: none;
  color: #fff;
  background-color: #47b8f7;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}

body .dash-white-tabs .ant-table-content {
  border: none;
  box-shadow: inherit;
}
.dash-white-tabs .ant-table-small {
  border: none;
}
body .dash-white-tabs .ant-table-content table thead tr th {
  background: #fff;
  color: #000;
  font-weight: 500;
}
.dash-white-tabs .table-grid-bx .ant-table-scroll {
  height: 250px;
}
body .recent-block-in .ant-table-content {
  box-shadow: inherit;
  border-radius: 4px;
}
.recent-block-in .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

body .recent-block-in .ant-table-content table thead tr th {
  background: #5a76e9;
  color: #fff;
  font-weight: 600;
}
body .recent-block-in .ant-table-content table tbody tr td {
  font-size: 13px;
  font-weight: 400;
  color: #666;
}
.text-right-export {
  text-align: right;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card-icon-bx {
  position: absolute;
  opacity: 0.4;
  bottom: 0;
  top: 0;
  right: 0;
}

.m-t-22 {
  margin-top: 22px !important;
}

.product-link {
  padding: 17px 250px 0 0;
}
.product-link a {
  font-size: 26px;
  font-weight: 700;
  color: #29b0e5;
}
.ant-col.custom-statcards.buttons.ant-col-xs-24.ant-col-sm-12.ant-col-md-12.ant-col-lg-6 {
  margin: 0 15px;
}
//.primary { width: 100%; font-size: 14px; background-color: transparent !important; margin-top: 32px; padding: 8px 16px 8px 16px; border: none; color: #ffffff; text-align: start; }
.custom-statcards.buttons h4 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.custom-statcards.buttons h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  margin: 0;
}
.text-left {
  text-align: left;
}
.primary.card-body-bx {
  background-image: linear-gradient(to right, #fea22e, #ffba0c);
  border-radius: 6px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
  padding: 15px;
}
.primary.purple-bg {
  background-image: linear-gradient(to right, #664dc9, #9884ea);
  border-radius: 6px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
  padding: 15px;
}
.primary.green-bg {
  background-image: linear-gradient(to right, #1d976c, #2fd38a);
  border-radius: 6px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
  padding: 15px;
}
.primary.red-bg {
  background-image: linear-gradient(to right, #f5222d, #ff878d);
  border-radius: 6px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
  padding: 15px;
}
.ant-col.custom-statcards.buttons.ant-col-xs-24.ant-col-sm-12.ant-col-md-12.ant-col-lg-6
  .card-icon-bx {
  position: absolute;
  opacity: 0.4;
  bottom: 0;
  top: 40px;
  right: 0;
}

.dashboard-heading-top.cards-heading h1 {
  font-size: 40px;
  margin-top: 15px;
}
.dashboard-heading-top.cards-heading p {
  font-size: 25px;
}
.container-center {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}
.primary {
  width: 100%;
  font-size: 14px;
  background-color: #096dd9 !important;
  margin-top: 32px;
  padding: 8px 16px 8px 16px;
  border: none;
  color: #ffffff;
}
.date-full-width .ant-form-item-control span {
  display: inline-block;
  width: 100%;
}

.container-mw-100 {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
.parentlogin {
  background-color: white;
  padding: 70px 15px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 350px;
  height: 100%;
}

.welcome-title h4 {
  font-weight: 500;
  font-size: 25px;
  color: #333333;
  margin-bottom: 17px;
}

.welcome-title p {
  color: #333;
  font-size: 14px;
  margin-bottom: 30px;
}

body .form-group-login .ant-form-item-label label {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 6px;
}

.text-right {
  text-align: right;
}
.form-group-login .ant-row.ant-form-item.input-box {
  margin: 0 0 7px;
}
.forgot-link a {
  color: #333;
  font-weight: 500;
  font-size: 13px;
}
.form-group-login .ant-form-item-children {
  display: inline-block;
  width: 100%;
}

.form-group-login .ant-form-item-children button {
  width: 100%;
  height: 45px;
  background-color: #ef487c;
  border: 1px solid #ef487c;
  margin-top: 30px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000021;
}
body .login-aside {
  height: 100%;
  padding: 70px 60px;
}
.login-head-section {
  margin: 0 0 50px;
}

.welcome-img {
  padding: 0 30px 50px;
}

.table-grid-bx table tbody tr td .ant-tag-green {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.table-grid-bx table tbody tr td .ant-tag-volcano {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-btn.Add-btn-top {
  position: relative;
  padding-left: 10px;
  text-transform: uppercase;
  font-weight: 600;
  width: 134px;
  height: 37px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.ant-btn.Add-btn-top-upload {
  background-color: #fff;
  color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  width: 100px;
  height: 37px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
}
.learning-target-btn .ant-btn.Add-btn-top-upload {
  background-color: #fff;
  color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  width: 100px;
  padding: 10px 16px;
  height: 32px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0 !important;
  margin-left: 0
}

.section-top-heading .ant-input-affix-wrapper input.ant-input {
  height: 37px;
  border-radius: 100px;
  padding: 0 20px 0;
  font-size: 14px;
  background: #fff;
}
.dash-bg-white.bg-blue-dark {
  background: rgb(35 42 113);
}
.ant-spin-spinning.loader-icon-fixed .ant-spin-dot {
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
}

button.ant-btn.ant-btn-primary.ant-btn-circle {
  margin-left: 10px;
  width: 100px;
  padding: 0px 30px;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  border-radius: 0px;
}
body .ant-table-thead > tr > th.ant-table-column-sort {
  background: #4f548d;
}
.dashboard-text-center {
  text-align: left;
  font-size: 40px;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  height: 35px;
  margin: auto;
  font-family: "HelveticaNeue-Medium" !important;
}

aside.sidebar-left.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px !important;
  flex: 0 0 80px !important;
}

.sidebar-left.ant-layout-sider-collapsed
  ul.ant-menu.ant-menu-light.ant-menu-root
  li
  a
  .anticon {
  left: 25px;
}
p.para-warning {
  color: #f94c4c;
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: #232a7159;
  font-size: 12px;
  line-height: 1;
  transform-origin: 50% 50%;
}
.border-bx {
  border: 1px solid #232a7138;
  border-radius: 10px;
}

.px-2 {
  padding-left: 6px;
  padding-top: 20px;
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-form-item-control {
  line-height: 0px;
  margin: 0;
}

.CreateRosterform {
    padding: 0 15px;
}

.CreateRosterform .ant-col-24.ant-form-item-label {
  padding-bottom: 3px;
}
.CreateRosterform .ant-form-item {
    margin: 8px 0;
}
.CreateRosterform  .ant-col-offset-6 {
  margin-left: 0%;
}
i.anticon.anticon-menu-fold.trigger.layout-trigger.header-toggle{
  margin: 0;
}


.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  margin-bottom: 24px ;
  vertical-align: top;
}
label.text-label {
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 500;
  margin: 0 15px;
}
label.text-form-label {
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 500;
margin: 10px 0;
}
label.text-form-label span {
  font-size: 16px;
  color: #7a7a7a;
  font-weight: 700;
margin: 10px 0;
}


.text-label span {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    margin: 0 2px;
}
.ant-form-item-control {
    line-height: 1em !important;
    margin: 5px 0;
}

.select-opt .ant-select-selection.ant-select-selection--single {
    height: 37px;
    border: 1px solid #232a7136;
}
.btn-level{
      text-align: end;
}

div.btn-level button {
    padding-left: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 15px;
    display: inline !important;
    width: 82px;
    height: 37px;
    border-radius: 6px;
}
.width-11 .table-scroll-auto table thead tr th,
.width-11 .table-scroll-auto table tbody tr td {
  width: 150px;
  min-width: 150px;
}
.width-11 .grand-total-bx table tbody tr td {
  width: 150px;
  min-width: 150px;
}
.table-scroll-auto table thead tr th {
  white-space: nowrap;
  width: 12.5%;
  font-size: 13px;
  padding: 10px 16px;
}
.dash-bg-white.no-padding .table-grid-bx {
  overflow: auto;
}
a.link-text {
  color: #1684ea;
  text-decoration: none;
  outline: none;
  border-radius: 100%;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.3s;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grand-total-bx thead tr {
  background-color: aquamarine;
  height: 45px;
  background: #4f548d;
  color: #FFF;
}

.status-label-row .status-label {
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
}
.status-label-row button.ant-btn {
  font-weight: 600;
  text-transform: uppercase;
  margin: 3px 0 0;
}
.btn-level.text-left {
  text-align: left;
}
.d-flex.status-label-row {
  justify-content: end;
  padding: 8px 16px;
}
.line-height .ant-form-item-control{
  line-height: 30px !important;
}
button.ant-btn.ant-btn-success {
  background-color: #0daf4b;
}
.label-head .ant-col.ant-col-24.ant-form-item-label {
  padding-bottom: 0;
}
.modal-label {
    font-weight: 300;
    color: #5f6b78;
    font-size: 16px;
    display: flex;
    justify-content: center;
}
.modal-p {
    font-weight: 700;
    color: #4f548d;
    font-size: 16px;
    display: flex;
    justify-content: center;
}
.modal-header {
    margin: 0 0 25px 0;
}

div.overflow .ant-col.ant-form-item-label {
  overflow: inherit;
}
a.link-text.diable-link {
  pointer-events: none;
  color: #1684ea;
  text-decoration: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 100%;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  // background-color: #fff !important;
}
.ant-alert.ant-alert-no-icon.alert-bx {
  padding: 8px 15px;
  margin: 5px;
}
span.ant-form-item-children select { /* display: block; */box-sizing: border-box;background-color: #fff;height: 30px;width: 100%;border: 1px solid #232a7136;border-top-width: 1.02px;border-radius: 4px;outline: none;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);-webkit-user-select: none;user-select: none;}
.btn-container{text-align: end;}
.btn-btn-range {width: 100%;}
.ant-row.row-grid-center.cards_row { display: flex; flex-wrap:wrap; }
.ant-card-cover img {height: 150px;object-fit: contain;border-radius: 2px 2px 0 0;padding: 25px 0 0;}
.ant-card-actions {margin: 0;padding: 0;list-style: none;background: #04040461 !important;border-top: 0 !important;zoom: 1;border-radius: 0 0 7px 7px;}
.ant-card-meta-title{margin-bottom: 0;}
.sidebar-left ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline li a svg{ color: #0c1362 !important}
button.btn.btn-outline-link {color: #2255a6 !important;opacity: initial;font-weight: 600;margin: 0 5pxpx;padding: 0;font-size: 12px;}
.svg-icon .anticon svg{color: #333;}
.modal-text {font-size: 12px;line-height: 20px;font-weight: 400;text-align: left;color: #808080;}
.list-disable{pointer-events: none;}
.btn.btn-outline-expand-secondary {color: #5a5a5a;margin: 25px 0px;border: 1px dotted #6c757d;font-weight: 600;padding: 2px 12px;}
.btn.btn-outline-expand-secondary:focus { outline: 0; box-shadow: none; }

.custom-navigation li.ant-menu-item.brand-logo {
  width: 208px;
  height: 80px;
  padding: 5px 0 0 16px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.custom-navigation li.ant-menu-item.brand-logo img {
  width: 100%;
  height: 100%;
  padding: 2px;
  object-fit: contain;
  object-position: left;
}

.Add-btn-top.ant-btn span.anticon-plus{
  position: static;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  font-size: 15px;
}

.table-th-bx {
  border-left: 1px solid rgb(221, 221, 221);
  padding-left: 12px;
}
.table-td-bx{
  border-left: 1px solid rgb(221, 221, 221);
}

.create-roaster-search input {margin-left: .5rem;border-radius:100px}
.print-table:last-child { padding: 0; border: none; }
.print-table {
    border-bottom: 3px solid #ddd;
    width: 900px;
    margin: auto;
    padding: 0 0 50px;
}

// 26 Sept 2023
.inactive-checkbox label {
  background: rgb(86 98 233 / 10%);
  padding: 10px 16px;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #333;
}
.ant-spin-container ul li.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #4f548d;
  color: #4f548d;
}
i.anticon-down svg {
  color: #9f9f9f;
}
.sidebar-left .anticon svg {
  display: inline-block;
  color: rgb(79 84 141);
}
.headerTop.ant-layout-header .anticon.anticon-menu-fold, .headerTop.ant-layout-header .anticon.anticon-menu-unfold{
  margin-right: 0;
  background: #4f548d;
  width: 78px;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 34px;
  justify-content: center;
}
// End 26 Sept 2023
.CreateRosterform .ant-row {
  position: static;
}
@media(max-width:767px){

.CreateRosterform{padding: 0 10px;}
header.headerTop.ant-layout-header ul {
  background: rgb(79 84 141 / 5%);
  // box-shadow: 0 5px 10px 0 rgb(86 61 124 / 15%);
}
.custom-navigation li.ant-menu-item.brand-logo {
  width: 208px;
  position: absolute;
  top: 0;
  left: 100px;
  height: 80px;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-btn-primary{text-align:center; }
.dashboard-heading-top h1{  text-align: left;line-height: 28px;font-size: 20px;}
.section-top-heading h3{font-size: 16px !important;}
.dashboard-text-center { text-align: left;  margin: 0;font-size: 26px; }
.ant-btn.Add-btn-top{width: 100%;font-size: 0.7rem; line-height: 0;}
.table-grid-bx .ant-table-body{overflow-x: auto !important;}
.ant-btn.Add-btn-top-upload{margin-left: 0;}
.modal-label-text { margin-left: 0px !important;}
.btn-container{text-align: start;}
.ant-radio-group{display: inherit !important;margin: 0 !important;}
.d-flex.status-label-row{flex-direction: column!important;padding: 0px;}
.btn-level{text-align: center !important;}
body .form-group-login .ant-form-item-label{padding-bottom: 0;}
body .form-group-login .ant-form-item-label label{margin-bottom:0;}
.form-group-login .ant-form-item-children button{margin-top: 0;}
.form-group-login .ant-row.ant-form-item{margin:0 0 15px;}
.ant-row .parentlogin{border-radius:16px;    padding: 1.5rem 2rem 2rem;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  transition: background-color 5000s ease-in-out 0s;
}
body .ant-form input[type=file] { box-shadow: inherit; border-radius: 2px; font-size: 12px; height: auto; background-color: #232a7100; padding: 4px 7px; border: 1px solid #232a7136; }
body .mainContnet.ant-layout-content{margin-left: 0;}
.btn-grid-wrapper button { padding: 10px 18px; font-size: 0.7rem; line-height: 2px; }
.inactive-checkbox label { background: rgba(86, 98, 233, 0.1); padding: 6px 4px; border-radius: 10px; font-size: 0.7rem; font-weight: 400; color: #333; white-space: nowrap;}
body .ant-layout-sider-children .ant-menu-root {
  height: 690px;
}
body .ant-layout-sider-children {
  overflow: auto;
}
}

@media(min-width:768px) and (max-width:1023px){
  .custom-navigation li.ant-menu-item.brand-logo {
    width: 208px;
    position: absolute;
    top: 0;
    left: 100px;
    height: 80px;
    padding: 5px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .dashboard-heading-top{text-align:center}
  .table-grid-bx .ant-table-body{overflow-x: auto !important;}
  .d-flex.status-label-row{justify-content:end !important; padding: 8px 16px;;}
  .btn-level{text-align: right;}
  .btn-grid-wrapper button { padding: 10px 18px; font-size: 0.7rem; line-height: 2px; }
  .inactive-checkbox label { background: rgba(86, 98, 233, 0.1); padding: 6px 4px; border-radius: 10px; font-size: 0.7rem; font-weight: 400; color: #333; }
  
}
@media(min-width:1024px){
  i.anticon.anticon-menu-fold.trigger.layout-trigger.header-toggle{display:none}
  .section-top-heading{justify-content: flex-end !important}
  .btn-level{text-align: right;}
}